import { Box, Card, CardActionArea, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import React from 'react'
import book1 from "../../assets/cov.png"
import { Link } from 'react-router-dom'
import "../cards/bookcards.css"
// import Button from '../Buttton/button'
const Bookdet = () => {
    return (
        <div >
            <Box className='flex  gap-6 ' sx={{ marginTop: "60px", marginBottom: "60px", textAlign:{xs:"center",sm:"start" ,md:"start"}, justifyContent:{xs:"cente", md:"start"}, alignItems:{xs:"center", md:"center"}, flexDirection:{xs:"column", sm:"row", md:"row"} }}>    
                <Box
                    component="img"
                    sx={{ width: 500 }}
                    alt="The house from the offer."
                    src={book1}
                />

                <Box>
                    <a target='_blank' href="https://www.amazon.com/Interstellar-Marine-Strike-Force-Saviors/dp/B0CPYYZ8BJ/ref=sr_1_1?crid=1YWBM8QXV2VB4&keywords=9798823018807&qid=1702640906&sprefix=9798823018807%2Caps%2C883&sr=8-1">
                        <Typography variant='h3' color={"#0A8754"} sx={{
                            '&:hover': {
                                textDecoration: "underline", color: "#ff9b02"
                            }
                        }}>Intersteller Marine Strike Force</Typography>
                    </a>
                    <Typography variant='body1' color={"white"} mt={2}>For the men of the Interstellar Marine Strike Force stationed aboard the ECS Stryker have at last returned to Earth. Expecting a hero's welcome, they instead find the war against the Serkins has followed them home. The only option is to fight, but the question is, can this humiliating blow be turned into a victory? Will the victory hold any weight, and how far will someone go to make that so?</Typography>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:{xs:"center", md:"start"}}}>
<a target='_blank' href={"https://www.amazon.com/Interstellar-Marine-Strike-Force-Saviors/dp/B0CPYYZ8BJ/ref=sr_1_1?crid=1YWBM8QXV2VB4&keywords=9798823018807&qid=1702640906&sprefix=9798823018807%2Caps%2C883&sr=8-1"}>
            <button className="btnn" type="button" style={{marginTop:25, padding:10, color:"white", fontSize: "19px"}}>
 Buy Now
</button>
</a>
{/* <Button /> */}
         
</Box>
                </Box>

              
            </Box>
        </div>
    )
}

export default Bookdet