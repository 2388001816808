import React from 'react'
import Pagination from '../components/BookList/Pagination'
import { useSelector } from 'react-redux';
import Footer from '../components/footer/Footer';
import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import {RiTwitterXLine } from 'react-icons/ri';


import {AiOutlineInstagram} from 'react-icons/ai';

const About = () => {
  return(
    <div className='mb-[400px] sm:mb-[170px]'>
     <Card sx={{ marginLeft: "auto", marginRight: "auto", marginTop:10,  marginBottom: "60px", color: "white", display: 'flex', flexDirection: { md: "row", xs: "column", sm: "column" }, textAlign: { xs: "center", md: "start" }, background:"none", width: "80%", justifyContent: "space-between", alignItems: "center", padding: 3 , }}>


<Box sx={{ display: 'flex', flexDirection: 'column', order: { xs: 1, md: 0 } }}>
  <CardContent  sx={{ justifyContent: "space-evenly", display: "flex", flexDirection: "column", flex: '-1 0 auto', color:"white"}}>
    <Typography component="div"  variant="h5" fontSize={{ xs: 28, sm: 28, md: 35 }} fontWeight={"bold"}>
      About the Author
    </Typography>
    <Typography variant="subtitle1" component="div" sx={{ marginTop: { xs: 1.5 }, marginRight: { md: 15 } }}>
    A chemist bored with a lack of ground-centered combat science fiction and a bit of free time from a virus that shall not be named. Said chemist got to writing and editing, eventually resulting in this book. A lifelong writer, this is the first for-profit venture.
    </Typography>
   
  </CardContent>
</Box>
<CardMedia
  component="img"
  sx={{ width: { md: "50%", xs: "100%", sm: "100%" }, height: { md: "50%", xs: "100%", sm: "100%" } }}
  image="https://websitedemos.net/ebook-author-02/wp-content/uploads/sites/576/2020/05/author-1-free-img.png"
  alt="Live from space album cover"
/>

</Card>
{/* 
<h2 class="title">Events</h2> */}
<div className='text-white mb-10 m-auto flex gap-3 flex-col w-3/4 mx-auto' >

<Typography variant="h4" sx={{textAlign:{xs:"center" , md:"start"}}}>Be the first to <span style={{color:"#0A8754", fontWeight:"bold"}}>Know</span></Typography>
<Typography variant="h5" sx={{textAlign:{xs:"center" , md:"start"}}}> <span style={{color:"#0A8754", fontWeight:"bold"}}>Upcoming</span> events</Typography>
<Typography variant="body1" className='' sx={{textAlign:{xs:"center" , md:"start"}, width:{xs:"100%", md:"25%"}}}>Watch this space for announcements of author appearances, signings, etc.</Typography>
<Stack direction="row" spacing={4} mt={2} justifyContent={{xs:"center", md:"start"}}>
                    <FaFacebookSquare className='icons' sx={{textAlign:{xs:"center" , md:"start"}}} size={30}/>
                    <RiTwitterXLine className='icons' size={30}/>
                    <AiOutlineInstagram className='icons' size={30}/>
                    <FaLinkedin classNam='icons' size={30}/>
                    {/* <FaSquareXTwitter className='icons'/> */}
                </Stack>
</div>
    <Footer/>
    </div>
  )
}

export default About
