import React, { useEffect } from 'react'
import Modall from '../components/ReviewModal/modal'
import { useDispatch, useSelector } from 'react-redux'
// import { fetchAllreviews } from '../store/review'
import { AiFillStar } from 'react-icons/ai'
import Footer from '../components/footer/Footer'
import Loaderr from '../components/Loader/loader'
import { fetchAllreviews } from '../store/product'
import { Button } from '@mui/material'
import axios from 'axios'

const Admin = () => {
  const {reviews, status} = useSelector((state)=> state.products) 
//   console.log(reviews);
  
  const dispatch = useDispatch()
  useEffect(()=>{
      dispatch(fetchAllreviews())
  },[])
// if (!reviews) {
//   return <div className='text-[50px]'>nothing</div>
// }

const DeleteReview = (id)=>{
    // console.log(id);
    axios.delete(`https://book-store-lime-eight.vercel.app/api/deletereview/${id}`)
    .then(res => {
      // console.log(res.data.data)
      // setAllUsers(res.data.data)
      dispatch(fetchAllreviews())

    })
    .catch(err =>
      console.log('--------err', err)
    )
}
  return (
    <div className='mb-[400px] sm:mb-[170px]'>
    <div className='mt-[120px] mb-28'>
      {status === "loading" ? <Loaderr/> : <>
      {/* <Modall/> */}
      <h1 className='text-white font-bold text-[35px]'>ADMIN TESTIMONIALS</h1>
      <div className='mt-10 flex flex-col gap-10'>

      {
        reviews.map((rev)=>(
          <div className='bg-gray-800 w-[90%] md:w-[60%] lg:w-[50%]  mx-auto rounded-lg p-3'>
            <div className='flex justify-between px-2'>
              <div>
            <p className='text-white font-bold text-[25px]'>{rev.name}</p>
            <p className='text-white font-semibold text-[20px]'>{rev.email}</p>
              </div>
              <div className='flex gap-1'>
              {[...Array(rev.rating).keys()].map((_, index) => (
                    <AiFillStar key={index} className='text-yellow-500' />
                  ))}

              </div>
         
            </div>
            
            <p className='text-white px-2 text-[18px] mt-5 w-[100%]'>{rev.message}</p>
            <Button onClick={()=>{DeleteReview(rev._id)}} variant="contained" style={{marginTop:"18px"}} color="error">
  Delete
</Button> 
          </div>
        ))
      }
      </div>
      </>
    }
    </div>
    <Footer/>
    </div>
   
  )
}

export default Admin