import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
// import { fetchAllProducts } from '../store/products';
import HomeCards from '../components/cards/HomeCard';
import AuthorCard from '../components/authorCard/AuthorCard';
import Footer from '../components/footer/Footer';
import CompCarousel from '../components/crousel/compCarousel';
import imgg from "../assets/IMG_0559.JPG"
import img1 from "../assets/IMG_0567.PNG"
import BookCards from '../components/cards/BookCards';

const Home = () => {
  // console.log(data);
  // const { homeData } = useSelector((state) => state.products);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   // Fetch data on component mount
  //   const pagination = { _page: 1, _limit: 4 };
  //   dispatch(fetchAllProducts(pagination));
  // }, [dispatch]); // Include dispatch as a dependency
  const data = [
    {
      name: "Intersteller Marine Strike Force",
      thumbnail : imgg,
      author: "Derek Stone",
    },
    {
      thumbnail :img1,
    },
    {
      thumbnail :img1,
    },
    {
      thumbnail :img1,
    }
  ]
  return (
    <div className='mb-[400px] sm:mb-[170px]'>
      <CompCarousel />
      <h2 className="title">Featured</h2>
      <HomeCards bookdata={data} mt={0}/>
      <h2 className="title">Author</h2>
      <AuthorCard />
      <Footer />
    </div>
  );
};

export default Home;
