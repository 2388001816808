import React, { useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import book1 from "../../assets/slider-01-2.png";
import book2 from "../../assets/partner-01.png";
import book3 from "../../assets/partner-03.png";
import book4 from "../../assets/partner-02.png";



import "./crousel.css";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Crousel_2 = () => {
  
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []); // Run once on mount
  console.log("component re-render");
  return (
    <>
    <Card sx={{ marginLeft: "auto", marginRight: "auto", display: 'flex', flexDirection: { md: "row", xs: "column", sm: "column" }, textAlign: { xs: "center", md: "start" }, width: "90%", justifyContent: "space-between", alignItems: "center", padding: 0, backgroundColor: "black" }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', order: { xs: 1, md: 0 } }}>
        <CardContent sx={{ justifyContent: "space-evenly", display: "flex", flexDirection: "column", flex: '-1 0 auto' }} data-aos="fade-right">
          {/* <Typography component="div" variant="h5" fontSize={{ xs: 28, sm: 28, md: 20 }} fontWeight="bold" fontFamily="Heebo, sans-serif" color="#0A8754">
            Derek Stone
          </Typography> */}
          <Typography component="div" variant="h5" mt={{md: 13, xs: 3}} fontSize={{ xs: 36.8, sm: 50, md:50, lg: 70 }} fontWeight="bold" fontFamily="EB Garamond, serif" color="#FFFF">
           Featured Books of the Month
          </Typography>
          <Box sx={{display:"flex", justifyContent:{xs: "center", md: "start"}}}>
          <CardMedia
        component="img"
        sx={{ width: { md: "16%", xs: "35%", sm: "30%" }, height: { md: "100%", xs: "70%", sm: "16%" }, marginTop: 3}}
        image={book2}
        alt="Live from space album cover"
      />
         <CardMedia
        component="img"
        sx={{ width: { md: "21.5%", xs: "50%", sm: "40%" }, height: { md: "100%", xs: "70%", sm: "40%" }, marginTop: 4, marginLeft:2  }}
        image={book3}
        alt="Live from space album cover"
      />
          <CardMedia
        component="img"
        sx={{ width: { md: "21.5%", xs: "50%", sm: "40%" }, height: { md: "100%", xs: "70%", sm: "40%" }, marginTop: 4, marginLeft:2  }}
        image={book4}
        alt="Live from space album cover"
      />
      </Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: { xs: "center", md: "start" } }}>
            <a target='_blank' href={"https://www.amazon.com/Interstellar-Marine-Strike-Force-Saviors/dp/B0CPYYZ8BJ/ref=sr_1_1?crid=1YWBM8QXV2VB4&keywords=9798823018807&qid=1702640906&sprefix=9798823018807%2Caps%2C883&sr=8-1 "}>
              {/* <button className="btnn" type="button" style={{ marginTop: 25 }}>
                <strong style={{color:"green"}}>Read Sample</strong>
                <div id="container-stars">
                  <div id="stars"></div>
                </div>
                <div id="glow">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
              </button> */}
              

              <button style={{marginTop: "29px"}} className="button" data-text="Awesome">
    <span className="actual-text">&nbsp;Purchase&nbsp;</span>
    <span aria-hidden="true" className="hover-text">&nbsp;Purchase&nbsp;</span>
</button>


            </a>
          </Box>
        </CardContent>
      </Box>
       
 
      <CardMedia
        component="img"
        data-aos="fade-left"
        sx={{ width: { md: "54%", xs: "100%", sm: "65%" }, height: { md: "100%", xs: "100%", sm: "65%" }, marginTop: 9}}
        image={book1}
        alt="Live from space album cover"
      />

    </Card>
    </>
  );
};

export default Crousel_2;

