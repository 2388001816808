import './App.css';
import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Navbar from './components/Navbar/Navbar';
import Headline from './components/headline/Headline';
import Footer from './components/footer/Footer';
import Books from './pages/Books';
import Contact from './pages/Contact';
import Review from './pages/Review';
import { useSelector } from 'react-redux';
import Loaderr from './components/Loader/loader';
import BookDet from './pages/BookDet';
import ParticleBg from './components/particleBg/ParticleBg';
import Admin from './pages/Admin';
import Gallery from './pages/gallery';

const App = () => {
    // const {data , status} = useSelector((state)=> state.products) 

    return (
        <>
         
        <Navbar/>
        <ParticleBg/>
        
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/books' element={<Books/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/reviews' element={<Review/>} />
                <Route path='/booksdetails' element={<BookDet/>} />
                <Route path='/gallery' element={<Gallery/>} />
                <Route path='/admin/derekstone/65207d560ff309cd68f92d6a' element={<Admin/>} />

                {/* <Route path='/about' element={<About/>} /> */}
                {/* and more pages you ant to make */}
            </Routes>
        </>
    )
}

export default App
