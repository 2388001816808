// // import React from 'react'
// // import "./crousel.css"
// // import { Box } from '@mui/material'
// // const Crousel = () => {
// //   return (
// //     <>
// //     <Box className='main'>
// //     <Box className='imgSlider' sx={{height: {xs:"40vh",sm:"80vh" ,md: "87vh"}}}>
// //     </Box>
// //     </Box>
// //     </>
// //   )
// // }

// // export default Crousel
import React, { useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import book1 from "../../assets/IMG_0559.JPG";
import "./crousel.css";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Crousel = () => {
  
  useEffect(() => {
    AOS.init();
  }, []); // Run once on mount
  console.log("component re-render");
  return (
    <>
    <Card sx={{ marginLeft: "auto", marginRight: "auto", display: 'flex', flexDirection: { md: "row", xs: "column", sm: "column" }, textAlign: { xs: "center", md: "start" }, width: "90%", justifyContent: "space-between", alignItems: "center", padding: 0, backgroundColor: "black" }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', order: { xs: 1, md: 0 } }}>
        <CardContent sx={{ justifyContent: "space-evenly", display: "flex", flexDirection: "column", flex: '-1 0 auto' }} data-aos="fade-right">
          <Typography component="div" variant="h5" fontSize={{ xs: 28, sm: 28, md: 20 }} fontWeight="bold" fontFamily="Heebo, sans-serif" color="#0A8754">
            Derek Stone
          </Typography>
          <Typography component="div" variant="h5" mt={1.5} fontSize={{ xs: 28, sm: 28, md: 50, lg: 80 }} fontWeight="bold" fontFamily="EB Garamond, serif" color="#FFFF">
            Intersteller Marine Strike Force
          </Typography>
          <Typography variant="subtitle1" color="white" component="div" sx={{ marginTop: { xs: 1.5 }, marginRight: { md: 15 } }}>
          For the men of the Interstellar Marine Strike Force stationed aboard the ECS Stryker have at last returned to Earth. Expecting a hero's welcome, they instead find the war against the Serkins has followed them home. The only option is to fight, but the question is....
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: { xs: "center", md: "start" } }}>
            <a target='_blank' href={"https://www.amazon.com/Interstellar-Marine-Strike-Force-Saviors/dp/B0CPYYZ8BJ/ref=sr_1_1?crid=1YWBM8QXV2VB4&keywords=9798823018807&qid=1702640906&sprefix=9798823018807%2Caps%2C883&sr=8-1 "}>
              {/* <button className="btnn" type="button" style={{ marginTop: 25 }}>
                <strong style={{color:"green"}}>Read Sample</strong>
                <div id="container-stars">
                  <div id="stars"></div>
                </div>
                <div id="glow">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
              </button> */}
              

              <button style={{marginTop: "25px"}} className="button" data-text="Awesome">
    <span className="actual-text">&nbsp;Buy&nbsp;now&nbsp;</span>
    <span aria-hidden="true" className="hover-text">&nbsp;Buy&nbsp;now&nbsp;</span>
</button>


            </a>
          </Box>
        </CardContent>
      </Box>
       
 
      <CardMedia
        component="img"
        data-aos="fade-left"
        sx={{ padding: { md: 7, xs: 3 }, width: { md: "40%", xs: "70%", sm: "65%" }, height: { md: "40%", xs: "70%", sm: "65%" }}}
        image={book1}
        alt="Live from space album cover"
      />

    </Card>
    </>
  );
};

export default Crousel;


