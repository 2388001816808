import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { BASE_URI } from "../Instance";

const STATUSES = {
    idle: "idle",
    loading: "loading",
    error: "error",
}

const ProductSlice = createSlice({
    name: "product",
    initialState: {
        status: STATUSES,
        reviews:[]
    },
    extraReducers: (builder) => {
      

        
        builder.addCase(fetchAllreviews.pending, (state, action) => {
            state.status = STATUSES.loading
        });
        builder.addCase(fetchAllreviews.fulfilled, (state, action) => {
            state.status = STATUSES.idle
            state.reviews = action.payload
        });
        builder.addCase(fetchAllreviews.rejected, (state, action) => {
            state.status = STATUSES.error
        });

    },
})

const { actions, reducer } = ProductSlice

export {

    reducer as ProductSlice,
}

export const fetchAllreviews = createAsyncThunk("products/fetchAllreviews",async ()=>{
    const data = await fetch(`https://book-store-lime-eight.vercel.app/api/allreviews`)
    // const data = await fetch(`${BASE_URI}get`)
    const products = await data.json();
    // console.log(products);
    return products;
})