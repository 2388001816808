import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Grid } from '@mui/material';
import book from "../../../src/assets/book.jpg"
import "./homecard.css"
import { Link } from 'react-router-dom';
import book1 from "../../assets/IMG_0559.JPG"

const HomeCards = ({ bookdata }) => {
    console.log(bookdata);
    return (
        <>
        <Grid container spacing={1} justifyContent="center" sx={{ width: "85%", marginLeft: "auto", marginRight: "auto" }} paddingX={{ xs: 2 }} >
            <Grid item>
          <Link to={"/booksdetails"}>

                            <Card className='zoom' sx={{ bgcolor: "transparent" , boxShadow: "none"}}>
                                <CardActionArea sx={{ padding: 0 }}>
                                    <CardMedia
                                        component='img'
                                        sx={{ width: 230, marginLeft: "auto", marginRight: "auto" }}
                                        image={book1}
                                        alt='unsplash image'
                                    />
                                </CardActionArea>
                                <CardContent sx={{ marginTop: 0, padding: 0, width: 230, marginLeft: "auto", marginRight: "auto" }}>
                                    <Typography gutterBottom variant='h5' component="div" color={"#0A8754"} sx={{ marginBottom: "0px" ,fontFamily: 'Playfair Display, serif'}}>Intersteller Marine Strike Force</Typography>
                                    <Typography gutterBottom variant='body1' component="div" color={"#fff"} sx={{}}><span style={{color:"white"}}>Published by: Derek Stone</span> </Typography>
                                </CardContent>
                            </Card>
                            </Link>
                            </Grid>
                            </Grid>
        </>
    );
}

//     <CardActionArea sx={{padding:0}}>
//     <CardMedia
//         component='img'
//         sx={{width:"100%"}}
//         image={book}
//         alt='unsplash image'
//     />
// </CardActionArea>
// <CardContent sx={{marginTop:0, padding:0}}>
//     <Typography gutterBottom sx={{marginTop:0}} variant='h5' component="div" color={"#0A8754"} textAlign={"center"}>title</Typography>
// </CardContent>




export default HomeCards 


