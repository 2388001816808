import React from 'react'
import "./loader.css"

const Loaderr = () => {
  return (
    
    <div style={{position:"absolute", top:"50%", left:"50%"}}>
<div className="loader">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>

    </div>
  )
}

export default Loaderr