import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import "./authorCard.css"
import { Link } from 'react-router-dom'

const AuthorCard = () => {
  return (
    <>
      <Card sx={{ marginLeft: "auto", marginRight: "auto", marginBottom: "60px", color: "", display: 'flex', flexDirection: { md: "row", xs: "column", sm: "column" }, textAlign: { xs: "center", md: "start" }, backgroundColor: "#e0e5d3", width: "80%", justifyContent: "space-between", alignItems: "center", padding: 3 }}>


        <Box  sx={{ display: 'flex', flexDirection: 'column', order: { xs: 1, md: 0 } }}>
          <CardContent sx={{ justifyContent: "space-evenly", display: "flex", flexDirection: "column", flex: '-1 0 auto', }}>
            <Typography component="div" variant="h5" fontSize={{ xs: 28, sm: 28, md: 35 }} fontWeight={"bold"}>
              About the Author
            </Typography>
            <Typography variant="subtitle1" color="black" component="div" sx={{ marginTop: { xs: 1.5 }, marginRight: { md: 15 } }}>
            A chemist bored with a lack of ground-centered combat science fiction and a bit of free time from a virus that shall not be named. Said chemist got to writing and editing, eventually resulting in this book. A lifelong writer, this is the first for-profit venture.
            </Typography>
            <button className='butnn' style={{ marginRight: 2, marginTop: 15 }}>
              <Link to={"/about"}>
              <p>ReadMore</p>
              </Link>
              <svg  
                strokeWidth={4}
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: { md: "50%", xs: "100%", sm: "100%" }, height: { md: "50%", xs: "100%", sm: "100%" } }}
          image="https://websitedemos.net/ebook-author-02/wp-content/uploads/sites/576/2020/05/author-1-free-img.png"
          alt="Live from space album cover"
        />

      </Card>

    </>
  )
}

export default AuthorCard
