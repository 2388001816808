
import { useState } from 'react'
import "./navbar.css"
import {
  AppBar,
  Toolbar,
  Box,
  Drawer,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { HiOutlineMenuAlt1 } from "react-icons/hi";

import { NavLink, useLocation } from 'react-router-dom';
const Navbar = () => {

  const [isDraweropem, setDrawerOpen] = useState(false)

  const routes = [
    { name: "HOME", path: "/" },
    { name: "BOOKS", path: "/books" },
    { name: "REVIEWS", path: "/reviews" },
    { name: "ABOUT", path: "/about" },
    { name: "CONTACT", path: "/contact" },
    { name: "GALLERY", path: "/gallery" },
  ]
  const {pathname} = useLocation()

  return (
    <>
      <Box width={{ md: "100%", xs: "100%" }} sx={{ marginLeft: "auto", marginRight: "auto", width: "100%" }} >
        <AppBar position="fixed" >
          <Toolbar className='nav' >
            <div className='flex items-center w-[100%] justify-between   md:hidden'   >
              {/* drawer Icon */}
              <Typography variant="h4" sx={{ fontSize:"25px" }} fontFamily={"'Space Mono', monospace"}>Derek Stone</Typography>
              <HiOutlineMenuAlt1 size={30} color='white' onClick={() => setDrawerOpen(true)} />
            </div>
            {/* Mobile Screens */}
            <Drawer className='bgCol' anchor='left' open={isDraweropem}
              onClose={() => setDrawerOpen(false)}
            >
              <div className='absolute right-1 top-2'>
                <CloseIcon onClick={() => setDrawerOpen(false)} htmlColor='white' className='text-white cursor-pointer' />
              </div>
              <div className='bgCol flex gap-5 justify-center items-center flex-col w-[330px] sm:w-[400px] h-[100%]' role="presentation" >
                {routes.map((route, i) => (
                  <NavLink key={i} className={`font-dest font-bold text-[18px] text-white ${pathname === route.path ? "activeClass": "noActiveClass"}`} to={route.path}>{route.name}</NavLink>
                ))}
              </div>
            </Drawer>
            {/* desktop screen */}
            <div className='md:flex items-center justify-between w-[100%] mx-8 hidden'>
        <Typography variant="h4" sx={{ fontSize:"31px" }} fontFamily={"'Space Mono', monospace"}>Derek Stone</Typography>
              <div className='hidden md:flex gap-5'>
                {routes.map((route, i) => (
                  <NavLink key={i} className={`font-bold text-[16px] text-white ${pathname === route.path ? "activeClass": "noActiveClass"}`} to={route.path}>{route.name}</NavLink>
                ))}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  )
}
export default Navbar