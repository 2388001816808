import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Pagination from '../components/BookList/Pagination';
import BookCards from '../components/cards/BookCards';
import Loaderr from '../components/Loader/loader';
import Footer from '../components/footer/Footer';
import imgg from "../assets/IMG_0559.JPG";
import img1 from "../assets/IMG_0567.PNG";
import HomeCards from '../components/cards/HomeCard';

const Books = () => {
  const [loading, setLoading] = useState(true); // Initial loading state
  const data = [
    {
      name: "Interstellar Marine Strike Force",
      thumbnail: imgg,
      author: "Derek Stone",
    },
    {
      thumbnail: img1,
    },
    {
      thumbnail: img1,
    },
    {
      thumbnail: img1,
    },
  ];

  useEffect(() => {
    // Simulate a 3-second loading delay
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Run the effect only once on component mount

  return (
    <div className='mb-[400px] sm:mb-[170px]'>
      {loading ? <Loaderr /> :<> <div>
        <BookCards bookdata={data} mt={15}/>
        {/* <Pagination /> */}
      </div>
      <Footer />
      </>
    }
    </div>
  );
};

export default Books;
