import React, { useEffect } from 'react'
import Modall from '../components/ReviewModal/modal'
import { useDispatch, useSelector } from 'react-redux'
// import { fetchAllreviews } from '../store/review'
import { AiFillStar } from 'react-icons/ai'
import Footer from '../components/footer/Footer'
import Loaderr from '../components/Loader/loader'
import { fetchAllreviews } from '../store/product'

const Review = () => {
  const {reviews, status} = useSelector((state)=> state.products) 
  console.log(reviews);
  
  const dispatch = useDispatch()
  useEffect(()=>{
      dispatch(fetchAllreviews())
  },[])
// if (!reviews) {
//   return <div className='text-[50px]'>nothing</div>
// }
  return (
    <div className='mb-[400px] sm:mb-[170px]'>
    <div className='mt-[120px] mb-28'>
      {status === "loading" ? <Loaderr/> : <>
      <Modall/>
      <h1 className='text-white font-bold text-[35px]'>TESTIMONIALS</h1>
      <div className='mt-20 flex flex-col gap-10'>

      {
        reviews.map((rev)=>(
          <div className='bg-gray-800 w-[90%] md:w-[60%] lg:w-[50%]  mx-auto rounded-lg p-3'>
            <div className='flex justify-between px-2'>
              <div>
            <p className='text-white font-bold text-[25px]'>{rev.name}</p>
            <p className='text-white font-semibold text-[20px]'>{rev.email}</p>
              </div>
              <div className='flex gap-1'>
              {[...Array(rev.rating).keys()].map((_, index) => (
                    <AiFillStar key={index} className='text-yellow-500' />
                  ))}
              </div>
            </div>
            <p className='text-white px-2 text-[18px] mt-5 w-[70%]'>{rev.message}</p>
          </div>
        ))
      }
      </div>
      </>
    }
    </div>
    <Footer/>
    </div>
  )
}

export default Review