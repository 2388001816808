import { Box } from '@mui/material';
import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { useForm } from "react-hook-form"
import { BiSolidMessageAdd } from "react-icons/bi"
import { AiFillStar } from 'react-icons/ai';
// import { useDispatch } from 'react-redux';
// import { addReview } from '../../store/review';
import axios from "axios"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#e0e5d3',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Modall = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(0)
    const [rating, setRating] = useState(0)
    console.log(rating);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
    };
    // const dispatch = useDispatch()
    const addReviews = async (obj) => {
        const val = { ...obj, rating }
        // dispatch(addReview(val))
        try{
            const res =  await axios.post("https://book-store-lime-eight.vercel.app/api/addreview", val)
  console.log(res);
  window.location.reload()
  
  if(res.status === 201){
      handleClose();  
  }
          }catch(err){
              console.log("Error in calling addUser api" , err);
          }
    }
    // const relod = ()=>{
    //    } 
    return (
        <>
            <div>
                <button onClick={() => { handleOpen() }} className='btnn fixed z-10 top-36 gap-1 right-10 flex items-center p-2 border text-white' style={{ fontSize: "20px" }}><BiSolidMessageAdd style={{ marginTop: "5px" }} /> YOURS</button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} width={{ xs: '90%', sm: "80%", md: "50%" }}>
                        <form className="space-y-6" onSubmit={handleSubmit(addReviews)}>
                            <div className="mt-1">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Enter Email address
                                </label>
                                <input
                                    id="email"
                                    {...register('email', { required: "email is required", pattern: { value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi, message: "email is not valid" } })}
                                    type="email"
                                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.email && <p className='text-red-500'>{errors.email.message}</p>}

                            </div>
                            <div className="mt-1">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Enter Name
                                </label>
                                <input
                                    id="name"
                                    name='name'
                                    {...register('name')}
                                    required
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            <div>
                                {[...Array(5).keys()].map((_, index) => {
                                    index += 1;
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`${index <= ((rating && hover) || hover)
                                                ? "text-yellow-500"
                                                : "text-gray-400"
                                                } bg-transparent border-none outline-none text-[22px] cursor-pointer `}
                                            onClick={() => setRating(index)}
                                            onMouseEnter={() => setHover(index)}
                                            onMouseLeave={() => setHover(rating)}
                                            onDoubleClick={() => {
                                                setHover(0);
                                                setRating(0);
                                            }}
                                        >
                                            <span>
                                                <AiFillStar />
                                            </span>
                                        </button>
                                    );
                                })}
                            </div>
                            <div className="mt-1">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Enter your views
                                </label>
                                <textarea name='message' required rows={"5"} {...register('message')} className='block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />

                            </div>


                            <div>
                                <button
                                  
                                    type="submit"
                                    className="flex w-1/3 ms-auto justify-center rounded-md btnn px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                >
                                    Submit Review
                                </button>
                            </div>
                        </form>
                        <div>

                        </div>
                    </Box>
                </Modal>

            </div>
        </>
    )
}

export default Modall