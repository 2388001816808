// import React from 'react'
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { Box, CardActionArea, Grid } from '@mui/material';
// import { Link, NavLink } from 'react-router-dom';
// import "./bookcards.css"
// import { useSelector } from 'react-redux';
// import Loaderr from '../Loader/loader';

// const BookCards = ({bookdata, mt}) => {

//   return (
//     <div style={{marginBottom: 35}}>
        
//       <Grid container spacing={4} sx={{ width:"90%", marginLeft: "auto", marginRight: "auto", marginTop:mt , }} >
//             {
//                 bookdata.map((book) => {
//                     // let thumbnail = book.volumeInfo.imageLinks.thumbnail
//                     return (

//                         <Grid item md={4} lg={3} xs={12} sm={6} sx={{}}>
//                           <Link to={"/booksdetails"}>
//                             <Card sx={{backgroundColor:"#282b46", padding:"14px 14px 8px", borderRadius: "24px",transition: "all 0.3s ease-in-out", // Adding transition effect
//                     '&:hover': {
//                       transform: 'translateY(-5px)', // Slightly move up on hover
//                     }}}>
//                             <CardActionArea sx={{ padding: 0 }}>
//                             <CardMedia
//                                         component='img'
//                                         sx={{ width: {md:"272px", sm: "300px", xs: "272px", lg:"370px"},height:{md:"272px", sm: "350px", xs: "272px",} , marginLeft: "auto", marginRight: "auto", borderRadius:"24px"}}
//                                         image={book.thumbnail}
//                                         alt='unsplash image'
// />
//                                  </CardActionArea>
//                                  <CardContent sx={{marginTop: 1, padding: 0, width:{xs: "auto",md:"auto"}, marginLeft: "auto", marginRight: "auto",padding:{xs:1,md:0} }}>
//                                     <Typography gutterBottom fontSize={"18px"}  component="div" color={"#fff"} sx={{ marginBottom: "0px" , fontFamily: 'Conthrax,sans-serif',fontWeight:"700"}}>{book.name}</Typography>
//                                     <Typography gutterBottom component="div" color={"#fff"} sx={{fontFamily: 'Conthrax,sans-serif', fontSize:"15px", marginTop: "5px"}}><span style={{color:"#fff"}}>{book.author ? `Published by : ${book.author}` : ""} </span></Typography>
//                                 </CardContent>

//                                 <Box sx={{textAlign:"end" ,marginTop: 2.5, marginBottom: 2.5}}>
//                                   <button className='viewbokBtn'>View Book</button>
//                                 </Box>
// </Card>
// </Link>
//                             {/* <Card sx={{ border:"1px solid gray",bgcolor:"transparent",  padding:{xs:0,md:2 } } }>
//                                 <CardActionArea sx={{ padding: 0 }}>
                                 
//                                 </CardActionArea>
//                                 <CardContent sx={{marginTop: 1, padding: 0, width:{xs: "auto",md:"auto"}, marginLeft: "auto", marginRight: "auto",padding:{xs:1,md:0} }}>
//                                     <Typography gutterBottom variant='h5' component="div" color={"#0A8754"} sx={{ marginBottom: "0px" , fontFamily: 'Playfair Display, serif',}}>{book.name}</Typography>
//                                     <Typography gutterBottom variant='body1' component="div" color={"#fff"} sx={{}}><span style={{color:"white"}}>{book.author ? `Published by : ${book.author}` : ""} </span></Typography>
//                                 </CardContent>
                              
//                                 <Box sx={{padding:{xs:1,md:0}, marginTop:2}}>

//                                 <button className='btnn p-2'>view book</button>

//                                 </Box>
                              
//                             </Card> */}
//                         </Grid>
//                     )
//                 })
//             }
//         </Grid>
//     </div>
//   )
// }

// export default BookCards
import React from 'react'
    import Card from '@mui/material/Card';
    import CardContent from '@mui/material/CardContent';
    import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Grid } from '@mui/material';
import book from "../../../src/assets/book.jpg"
import "./homecard.css"
import { Link } from 'react-router-dom';


const BookCards = ({ bookdata , mt}) => {
    console.log(bookdata);
    return (
        <Grid container spacing={1} justifyContent="center" sx={{ width: "85%", marginLeft: "auto", marginRight: "auto" ,marginTop: mt}} paddingX={{ xs: 2 }} >
            {
                bookdata.map((book) => {
                    // console.log(book.thumbnail); 
                    // let thumbnail = book.volumeInfo.imageLinks.thumbnail
                    return (

                        <Grid item md={3} xs={12} sm={6} sx={{}}>
                            <Link to={"/booksdetails"}>
                            <Card className='zoom' sx={{ bgcolor: "transparent" }}>
                                <CardActionArea sx={{ padding: 0 }}>
                                    <CardMedia
                                        component='img'
                                        sx={{ width: 230, marginLeft: "auto", marginRight: "auto" }}
                                        image={book.thumbnail}
                                        alt='unsplash image'
                                    />
                                </CardActionArea>
                                <CardContent sx={{ marginTop: 0, padding: 0, width: 230, marginLeft: "auto", marginRight: "auto" }}>
                                    <Typography gutterBottom variant='h5' component="div" color={"#0A8754"} sx={{ marginBottom: "0px" ,fontFamily: 'Playfair Display, serif'}}>{book.name}</Typography>
                                    <Typography gutterBottom variant='body1' component="div" color={"#fff"} sx={{}}><span style={{color:"white"}}>{book.author ? `Published by : ${book.author}` : ""}</span> </Typography>
                                </CardContent>
                            </Card>
                            </Link>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}

//     <CardActionArea sx={{padding:0}}>
//     <CardMedia
//         component='img'
//         sx={{width:"100%"}}
//         image={book}
//         alt='unsplash image'
//     />
// </CardActionArea>
// <CardContent sx={{marginTop:0, padding:0}}>
//     <Typography gutterBottom sx={{marginTop:0}} variant='h5' component="div" color={"#0A8754"} textAlign={"center"}>title</Typography>
// </CardContent>




export default BookCards 



