import React from 'react'
import Bookdet from '../components/bookdetails/bookdet'
import { Box } from '@mui/material'
import Footer from '../components/footer/Footer'

const BookDet = () => {
  return (
    <div className='mb-[400px] sm:mb-[170px]'>
       <Box sx={{width:"89%", marginTop: 20, marginX:"auto", color:"#ff9b02"}}>

    <Bookdet />
    </Box>
    <Footer/>
    </div>
  )
}

export default BookDet