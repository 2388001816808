// import React, { useState, useEffect } from 'react';
// import { useSpring, animated, config } from 'react-spring';
// import Crousel from './Crousel';
// import Crousel_1 from './ImgComp';
// import Crousel_2 from './imgComp2';


// const components = [<Crousel_1/>, <Crousel />, <Crousel_2/>];
// // const components = [<Crousel_1/>];


// const CompCarousel = () => {
//     const [index, setIndex] = useState(0);

//     // Create a spring for the opacity animation with a slower duration
//     const fade = useSpring({
//       opacity: 1,
//       from: { opacity: 0 },
//       config: {duration: 3000}, // Use a slower spring configuration
//       reset: true,
//       onRest: () => {
//         setIndex((prevIndex) => (prevIndex + 1) % components.length);
//       },
//     });
  
//     useEffect(() => {
//       // Set up interval to change component every 5 seconds
//       const intervalId = setInterval(() => {
//         setIndex((prevIndex) => (prevIndex + 1) % components.length);
//       }, 6000);
  
//       // Clean up interval on component unmount
//       return () => clearInterval(intervalId);
//     }, []);
  
//     return (
//       <div>
//         <h2>Carousel</h2>
//         <animated.div style={fade}>{components[index]}</animated.div>
//       </div>
//     );
// };

// export default CompCarousel;

import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import Crousel from './Crousel';
import Crousel_1 from './ImgComp';
import Crousel_2 from './imgComp2';

const components = [<Crousel_1 />, <Crousel />, <Crousel_2 />];

const CompCarousel = () => {
  const [index, setIndex] = useState(0);

  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 3000 },
    reset: true,
    onRest: () => {
      setIndex((prevIndex) => (prevIndex + 1) % components.length);
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % components.length);
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ backgroundColor: 'transparent' }}>
      <h2>Carousel</h2>
      <animated.div style={{ ...fade, backgroundColor: 'rgba(0, 0, 0, 0)' }}>
        {components[index]}
      </animated.div>
    </div>
  );
};

export default CompCarousel;
