import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { FaFacebookSquare, FaLinkedin, FaSquareXTwitter } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import Footer from '../components/footer/Footer';
import {RiTwitterXLine } from 'react-icons/ri';
    
const Contact = () => {
    return (
<>
        <Box sx={{ width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 14 }} className="mb-[400px] sm:mb-[170px]">
            <Grid container>
                <Grid item sm={6} xs={12} p={3}>
                  
                    <Stack direction="column" spacing={8} justifyContent={"center"} height={"100%"} >
                        <div>
                        <Typography variant='h5' component="div" sx={{ color: "#0A8754" }}>
                        You can find us at
                    </Typography>
                            <Typography variant='body1' component="div" sx={{ color: "gray", fontSize:"14px",  }}>EMAIL</Typography>
                            <Typography variant='h6' component="div" sx={{ color: "white" }}>
                            DSauthor@proton.me
                            </Typography>
                        </div>
                        {/* <div>

                            <Typography variant='body1' component="div" sx={{ color: "gray", fontSize:"14px",}}>PHONE NUMBER</Typography>

                            <Typography variant='h6' component="div" sx={{ color: "white" }}>
                                202-555-0188

                            </Typography>
                        </div> */}
                        {/* <div>

                            <Typography variant='body1' component="div" sx={{ color: "gray", fontSize:"14px", }}>LOCATION</Typography>
                            <Typography variant='h6' component="div" sx={{ color: "white" }}>
                                2360 Hood Avenue, San Diego, CA, 92123
                            </Typography>
                        </div> */}


                        <Stack direction="row" spacing={4}>
                            <FaFacebookSquare className='icons' size={30} />
                            <RiTwitterXLine className='icons' size={30}/>
                            <AiOutlineInstagram className='icons' size={30} />
                            <FaLinkedin className='icons' size={30} />
                            {/* <FaSquareXTwitter className='icons'/> */}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item sm={6} xs={12}>

                    <Box className="mainbox" sx={{ width: "100%", marginLeft: "auto", marginRight: "auto",padding: 3 }}>
                        <Typography variant='h5' component="div" sx={{ color: "white" }}>
                            Send us Message
                        </Typography>
                        <form
  action="https://formspree.io/f/mjvqpwpw"
  method="POST"
>

                        
                        <Box sx={{ marginTop: 3, display:"flex", flexDirection:"column", gap:3 }}>
                            <div className='input-box'>

                                <label>name</label>
                                <input type="text" name='name'/>
                            </div>
                            <div className='input-box'>

                                <label >email</label>
                                <input type="email" name='email'/>
                            </div>
                            <div className='input-box'>

                                <label >message</label>
                                <textarea name="message" id="" cols="30" rows="10"></textarea>
                            </div>
                        </Box>
                        <Box sx={{textAlign:"center", marginTop:3}}>
                            <input type="submit" value="Contact us"  className='w-48 btnn p-2 border text-white' style={{fontSize:"20px", fontWeight:"bold", }}/>

                        </Box>
                        </form>
                    </Box>
                </Grid>

            </Grid>

        </Box>

        <Footer/>
        </>
    )
}

export default Contact