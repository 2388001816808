import React from 'react'
import des1 from "../assets/des1.png"
import des2 from "../assets/des2.jpg"
import des4 from "../assets/des4.png"
import des3 from "../assets/des3.png"
import Footer from '../components/footer/Footer'
import { Box } from '@mui/material'

import "../App.css"
const Gallery = () => {
  return (
    <>
      <Box className="text-gray-600 body-font" paddingBottom={{xs: 110, md: 27}}>
      {/* <section className="text-gray-600 body-font">
  <div className="container px-5 mx-auto flex flex-col">
    <div className="lg:w-2/3 mx-auto">
      <div className="rounded-lg overflow-hidden p-20">
    <h1 className="text-4xl title-font text-white text-center tracking-widest" style={{fontWeight:"bold"}}>Main Cast</h1>

        <img
          alt="content"
          className="object-cover object-center h-full w-full mt-3"
          src={des1}
        />
      </div>

    </div>
  </div>
</section> */}

        <div className="container px-5 pt-28 mx-auto flex flex-wrap">
          {/* Image Container */}
          <div className="lg:w-2/5 w-full lg:h-auto ">
            <img
              src={des4}
              alt="ecommerce"
              className="w-50 h-50 object-cover object-center"
            />
          </div>
          
          {/* Text Container */}
          <div className="lg:w-3/5 w-full lg:pl-10 lg:py-6 mt-5 lg:mt-auto lg:mb-auto" style={{ maxHeight: '400px' }}>
            <h2 className="text-4xl title-font text-white text-center tracking-widest" style={{fontWeight:"bold"}}>
            The M68A2
            </h2>
            <p className="leading-relaxed text-white text-center font-dest" style={{ fontSize: '15px', marginTop:8 }}>
            The M68A2 is a 25-kilogram Gauss rifle, the standard assault weapon for the Strike Force Marines. It is powered by a Zinc-oxide battery that accelerates a 6.8x40mm slug to velocities that can penetrate up to 40mm of RHA.
            </p>
          </div>
          {/* Additional Content */}
          {/* <div className="flex mb-4 flex-col">
            <h1 className="text-gray-900 text-2xl title-font font-medium mb-1">
            Dmitri Cherniak
            </h1>
            <span className="flex py-2 border-l-2 border-gray-200 space-x-2s">
            <a className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-7 h-7"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
              </svg>
            </a>
            <a className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-7 h-7"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
              </svg>
            </a>
            <a className="text-gray-500">
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-7 h-7"
                viewBox="0 0 24 24"
              >
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z" />
              </svg>
            </a>
          </span>
          </div> */}
          </div>

          <Box className="container px-5 mx-auto flex flex-wrap" paddingTop={{xs:9, md: 12}}>
          {/* Image Container */}
          <div className="lg:w-2/5 w-full lg:h-auto h-64 ">
            <img
              src={des2}
              alt="ecommerce"
              className="w-full h-full object-cover object-center lg:mt-12 "
            />
          </div>
          {/* Text Container */}
          <div className="lg:w-3/5 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ maxHeight: '400px' }}>
            <h2 className="text-4xl title-font text-white text-center tracking-widest" style={{fontWeight:"bold"}}>
            The DD468 ECS
            </h2>
            <p className="leading-relaxed text-white text-center font-dest" style={{ fontSize: '14px', marginTop:8 }}>
            The DD468 ECS Robert F Stryker; or simply Stryker for short, is a Warrior Class Destroyer. The Warrior class is always named after Soldiers some well known for their heroics while others were forgotten to history that were finally given respect. This ship is 800,000 metric tons in mass and is part of the 7th fleet. The ship is the 468th vessel of its class to be built.  With the color you can see the hangers better and even the direction thrusters as well as combat roundels for the ECF. Like all destroyers is features 4 meter armor plate with an internal honeycomb structure over its hull. The armor is highly protective against plasma weaponry and is supported with a particle barrier. The ship can resupply quickly thanks to the sealed modular design.  All warriors of this Block 1 design are part of the 2130 re-armament plan of the Earth Colonial Space Forces. The ship is designed to perform both ship to ship combat and provide orbital security to the on board expeditionary combat teams. ECTs are an advanced combination of Drone and human combined arms combat forces so long as they are fully equipped.<br/>
            The Warrior class can roughly be described as an extra-large frigate, thanks to the additional internal capacity over frigates. Each destroyer has a large drone fighter escort wing. Of course they are best used as hit and run vessels at the hundreds of thousands of kilometer range with their devastating particle weapons. Such heavy firepower and ground troop support allows the ECF to deal with any threat in regardless of battlespace.  

            </p>
          </div>
          {/* Additional Content */}
  
          </Box>

          
          </Box>
       
          <Footer/>

    </>
  )
}

export default Gallery