import React from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import {AiOutlineInstagram} from 'react-icons/ai';
import {RiTwitterXLine } from 'react-icons/ri';
const Footer = () => {
    return (
        <Box className='footer' sx={{padding:2,}}>
            <Stack direction={{md:"row", xs:"column"}} justifyContent={{xs:"center", md:"space-between"}} spacing={{xs:2, md:0}} alignItems={"center"} sx={{padding:2,}}>
                <Box >
                    <Typography variant='h5' fontFamily={"'Space Mono', monospace"} color={"white"}>Derek Stone</Typography>
                </Box>
                <Stack direction={{sm:"row", xs:"column"}} alignItems={"center"} spacing={2} >
                    <NavLink to={"/"} className="links">
                        HOME
                    </NavLink>
                    <NavLink to={"/books"} className="links">
                        BOOKS
                    </NavLink>
                    <NavLink to={"/reviews"} className="links">
                        REVIEWS
                    </NavLink>
                    <NavLink to={"/about"} className="links">
                        ABOUT
                    </NavLink>
                    <NavLink to={"/contact"} className="links">
                        CONTACT US
                    </NavLink>
                    <NavLink to={"/gallery"} className="links">
                        GALLERY
                    </NavLink>
                </Stack>
            </Stack>
            <Divider color="#292929"/>
            <Stack direction={{sm:"row", xs:"column"}} spacing={{xs:2, md:0}} justifyContent={"space-between"} alignItems={"center"} sx={{padding:2,}}>
                <Typography color={"white"}>
                    @2024 all rights reserved | Powered by Team 3DotMedia
                </Typography>
                <Stack direction="row" spacing={4}>
                    <FaFacebookSquare className='icons' size={30}/>
                    <RiTwitterXLine className='icons' size={30}/>
                    <AiOutlineInstagram className='icons' size={30}/>
                    <FaLinkedin className='icons' size={30}/>
                    {/* <FaSquareXTwitter className='icons'/> */}
                </Stack>
            </Stack>
        </Box>
    )
}

export default Footer